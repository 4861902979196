/*****************************************************************************************
 * 설명 : 기존 구 사이트 결과 링크
 * URI : /service/show, /service/fshow
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { showDialog } from 'reducers/dialogReducer';

import { Restful } from 'service/restful';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const ServiceResultShow = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [queryString, setQueryString] = useState({});

  /***************************************************************************************
   * 설명 : 서비스 정보 가져오기
  ***************************************************************************************/
  const getList = () => {
    let params = {
      program: 'home',
      service: 'services',
      action: 'getServiceOldResult',
      version: '1.0',
      ...queryString
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.data ) {
        let url = '/service/result?serviceSeq=' + response.data.data + '&paySeq=' + (queryString?.pi ?? '') + '&itemkey=' + queryString?.pk;
        navigate(url);

      } else {
        dispatch(showDialog({header: '안내', message: '정상적인 경로로 접근하시기 바랍니다.', click: () => navigate('/')}));
        return;
      }
    })
  }

  /***************************************************************************************
   * 설명 : URI로 넘어온 서비스 정보 가져오기
  ***************************************************************************************/
  useEffect(() => {
    if( ( queryString?.pk ?? '') !== '' ) {
      getList();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString])

  /***************************************************************************************
   * 설명 : 데이터 로딩
  ***************************************************************************************/
  useEffect(() => {
    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    setQueryString(query);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search, Location]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <></>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default ServiceResultShow;