import { createSlice } from '@reduxjs/toolkit';

const dialogSlice = createSlice({
  name: 'dialog',
  initialState: {
    header: '',
    message: '',
    isDialog: false,
    click: null
  },
  reducers: {
    showDialog: ( state, action ) => {
      return {
        header: action.payload.header,
        message: action.payload.message,
        isDialog: true,
        click: action.payload.click
      }
    },
    hideDialog: ( state ) => {
      return {
        hedaer: '',
        message: '',
        isDialog: false,
        click: null
      }
    }
  }
})

export const { showDialog, hideDialog } = dialogSlice.actions;
export default dialogSlice.reducer;