/*****************************************************************************************
 * 설명 : Footer
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import './footer.scss';

import footerLogo from 'assets/images/logo_footer.png';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// import footerLogoM from 'assets/images/footer/logo_footerm.png';

import TopButton from './topButton';


/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Footer = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [view, setView] = useState(false);

  /***************************************************************************************
   * 설명 : html 선언부
  ************************************************************`***************************/
  return (
    <footer className="home-footer">
      <div className="inner clearfix">
        <NavLink to="/" className="footer-logo cp">
          <img src={footerLogo} alt="사주인" className="pc-only"/>
          {/* <img src={footerLogoM} alt="한국냉동공조안전관리원" className="mobile-only"/> */}
        </NavLink>

        <div className="mobile-only h4" onClick={() => setView(!view)}>회사정보<span className="icon1"><KeyboardArrowDownIcon/></span></div>

        <section className={view? "com-info view": "com-info"}>
          <p>법인명: 주식회사 소나무소프트</p>
          <p>대표 : 채연선</p>
          <p className="last">주소 : 서울특별시 서초구 서초대로 398 BNK디지털타워 4층 428호</p>
          <br className="pc-only" />
          <p>사업자등록번호 : 276-81-00606</p>
          <p>통신판매업신고 : 2024-서울서초-2770</p>
          <p className="last">문의 : sajuin@sajuin.com</p>
          <br className="pc-only" />
          <div className="copyright pc-only">Copyright ⓒ 2016. SAJUIN. All Rights Reserved.</div>
        </section>

        <div className="fmenu">
          <ul className="clearfix">
            <li><NavLink to="/contents?seq=97" className="fmenu-list">이용약관</NavLink></li>
            <li><NavLink to="/contents?seq=98" className="fmenu-list">개인정보처리방침</NavLink></li>
          </ul>
        </div>
        <div className="copyright mobile-only">Copyright ⓒ 2016. SAJUIN. All Rights Reserved.</div>
        <TopButton></TopButton>
      </div>
    </footer>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Footer;