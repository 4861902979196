/*****************************************************************************************
 * 설명 : 메뉴 리스트
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React from 'react';

export const  initMenuList1 = [
  {
    id: 2, title: '검사안내', url: '/inspectionGuide/RefrigeratingMachine',
    submenu: [
      { id: 21, title: '냉동기 및 냉동용특정설비', url: '/inspectionGuide/RefrigeratingMachine' },
      { id: 22, title: '냉동제조시설', url: '/inspectionGuide/RefrigerationFacilities' },
      { id: 23, title: '안전밸브 작동성능확인', url: '/inspectionGuide/SafetyValve' },
      { id: 24, title: '검사수수료', url: '/inspectionGuide/InspectionFee' },
    ]
  },
  {
    id: 3, title: '자료실', url: '/information/gas',
    submenu: [
      { id: 31, title: '고압가스안전관리법', url: '/information/gas' },
      { id: 32, title: 'KGS CODE', url: '/information/kgscode' },
      { id: 33, title: '냉동제조시설 관리규정', url: '/information/frz' },
      { id: 34, title: '최근개정법령 및 입법예고', url: '/board/list?boardId=pds2' },
      { id: 35, title: '자료실', url: '/board/list?boardId=pds' },
    ]
  },
  {
    id: 4, title: '고객지원', url: '/board/list?boardId=notice',
    submenu: [
      { id: 41, title: '공지사항', url: '/board/list?boardId=notice' },
      { id: 42, title: '냉동공조뉴스', url: '/board/list?boardId=news' },
      { id: 43, title: '합격증명서 발행 및 확인방법', url: '/customer/PassCertificate' },
      { id: 44, title: '안전밸브 작동확인 신청', url: '/customer/SafeList' },
    ]
  },
  {
    id: 1, title: '기관소개', url: '/intro/greetings',
    submenu: [
      { id: 11, title: '인사말', url: '/intro/greetings' },
      { id: 12, title: '일반현황', url: '/intro/history' },
      { id: 13, title: '조직안내', url: '/intro/organization' },
      { id: 14, title: '주요업무', url: '/intro/business' },
      { id: 15, title: '오시는 길', url: '/intro/location' },
    ]
  }
];

export const  initMenuList = [
  {seq: 1, icon: '<span class="material-icons-outlined menu-icon f14"> home </span>', pageCode: '001', pageName: 'HOME', linkUrl: '/admin/dashboard' },
  {seq: 1, icon: '<span class="material-icons-outlined menu-icon f14"> home </span>', pageCode: '002', pageName: '기본간명', linkUrl: '/admin/saju' },
  {seq: 2, icon: '<span class="material-icons-outlined menu-icon f14"> home </span>', pageCode: '003', pageName: '결제관리', linkUrl: '' },
  {seq: 3, pageCode: '003001', pageName: '구매내역', linkUrl: '/admin/payment', isMainmenuView: '1' },
  {seq: 4, icon: '<span class="material-icons-outlined menu-icon f14"> home </span>', pageCode: '004', pageName: '서비스DB', linkUrl: '' },
  {seq: 5, pageCode: '004001', pageName: '일년신수(전반기)', linkUrl: '/admin/db/year1', isMainmenuView: '1' },
  {seq: 6, pageCode: '004002', pageName: '일년신수(후반기)', linkUrl: '/', isMainmenuView: '1' },
  {seq: 7, pageCode: '004003', pageName: '토정총운', linkUrl: '/', isMainmenuView: '1' },
  {seq: 8, pageCode: '004004', pageName: '토정월별운', linkUrl: '/', isMainmenuView: '1' },
  {seq: 9, pageCode: '004005', pageName: '토정월별운2', linkUrl: '/', isMainmenuView: '1' },
  {seq: 10, pageCode: '004006', pageName: '총운(전체타이틀)', linkUrl: '/', isMainmenuView: '1' },
  {seq: 11, pageCode: '004007', pageName: '격국DB', linkUrl: '/', isMainmenuView: '1' },
  {seq: 12, pageCode: '004008', pageName: '오행DB', linkUrl: '/', isMainmenuView: '1' },
  {seq: 13, pageCode: '004008', pageName: '십성DB', linkUrl: '/', isMainmenuView: '1' },
  {seq: 14, icon: '<span class="material-icons-outlined menu-icon f14"> home </span>', pageCode: '005', pageName: '성격32', linkUrl: '' },
  {seq: 15, pageCode: '005001', pageName: '타고난성격DB', linkUrl: '/', isMainmenuView: '1' },
  {seq: 16, pageCode: '005002', pageName: '적성진로DB', linkUrl: '/', isMainmenuView: '1' },
  {seq: 17, icon: '<span class="material-icons-outlined menu-icon f14"> home </span>', pageCode: '006', pageName: '시스템설정', linkUrl: '', isMainmenuView: '1' },
  {seq: 18, pageCode: '006001', pageName: '사이트설정', linkUrl: '/admin/system/site', isMainmenuView: '1' },
  {seq: 19, pageCode: '006002', pageName: '모듈설정', linkUrl: '/admin/system/module', isMainmenuView: '1' },
  {seq: 20, pageCode: '006003', pageName: '메뉴설정', linkUrl: '/admin/system/menu', isMainmenuView: '1' },
  {seq: 21, pageCode: '006004', pageName: '게시판설정', linkUrl: '/admin/system/board', isMainmenuView: '1' },
  {seq: 22, pageCode: '006005', pageName: '권한그룹관리', linkUrl: '/admin/system/auth/group', isMainmenuView: '1' },
  {seq: 22, pageCode: '006006', pageName: '권한관리', linkUrl: '/admin/system/auth', isMainmenuView: '1' },
];

/*****************************************************************************************
 * 설명 : context 선언부
*****************************************************************************************/
export const GlobalMenu = React.createContext({
  menuList: [],
  setMenuList: () => {},

  menuIndex: -1,
  setMenuIndex: () => {},

  menuReduce: '',
  setMenuReduce: () => {}
});

/*****************************************************************************************
 * 설명 : 디폴트 선언
*****************************************************************************************/
export default GlobalMenu;