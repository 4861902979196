/* eslint-disable jsx-a11y/anchor-is-valid */
/*****************************************************************************************
 * 설명 : 운세 서비스
 * URI : /services
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { Restful } from 'service/restful';
/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const ServiceTodayResult = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [vars, setVars] = useState({});
  const [info, setInfo] = useState({});
  const [summary, setSummary] = useState({});

  /***************************************************************************************
   * 설명 : 서비스 정보 가져오기
  ***************************************************************************************/
  const getList = () => {
    let params = {
      program: 'home',
      service: 'services',
      action: 'getServiceTodayDetail',
      version: '1.0',
      ...props?.queryString
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result) {
        if( response.data.vars ) {
          setVars(response.data.vars);
        } else {
          setVars([]);
        }

        if( response.data.data && response.data.data.length > 0 ) {
          setData(response.data.data);
        } else {
          setData([]);
        }

        if( response.data.info && response.data.info.length > 0 ) {
          setInfo(response.data.info[0]);
        } else {
          setInfo([]);
        }

        if( response.data.summary && response.data.summary.length > 0 ) {
          setSummary(response.data.summary);
        } else {
          setSummary([]);
        }

      } else {
        setData([]);
      }
    })
  }

  /***************************************************************************************
   * 설명 : 데이터 로딩
  ***************************************************************************************/
  useEffect(() => {
    if( (props.serviceInfo?.seq ?? '') !== '' ) {
      getList();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.serviceInfo])

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="services-page">
      <section className="service-introduce">
        <h3 className="contents-title mb30"><span>나의 맞춤 운세</span></h3>

        <div className="service-txt">
          <blockquote>
            <i className="fa fa-quote-left"></i>오늘의 나의 행동이 내일의 나를 결정합니다.<br />하루의 기운을 미리 알아보고 소중한 당신의 오늘을 멋지게 가꿔 보세요!
          </blockquote>
        </div>
      </section>

      <div id="post-content">
        <div className="today-info clearfix">
          <div className="todaybox4">
            <p>서기 {vars?.iljin_today_yangdate_str}(음력  {vars?.iljin_today_umdate_str}) {vars?.iljin_weekday_kr}요일</p>
            <p>
              단기 {parseInt(vars?.iljin_today_yangdate_str?.substr(0,4) ?? 0) + 2333}년 /
              불기 {parseInt(vars?.iljin_today_yangdate_str?.substr(0,4) ?? 0) + 544}년</p>
            <p>" {moment().format('YYYY')}년은 <span>{(info?.name ?? '')}의 해</span>입니다. "</p>
          </div>
          <div className="todaybox3">
            <table>
              <tr>
                <td className="line"><span>年</span></td>
                <td className="line"><span>月</span></td>
                <td className="line"><span>日</span></td>
              </tr>
              <tr>
                <td><span className="st3">{vars?.iljin_today_year_h}{vars?.iljin_today_year_e}  </span></td>
                <td><span className="st3">{vars?.iljin_today_month_h}{vars?.iljin_today_month_e}  </span></td>
                <td><span className="st3">{vars?.iljin_today_day_h}{vars?.iljin_today_day_e}  </span></td>
              </tr>
              <tr>
                <td><span>{vars?.iljin_today_year_h_kr}{vars?.iljin_today_year_e_kr}</span></td>
                <td><span>{vars?.iljin_today_month_h_kr}{vars?.iljin_today_month_e_kr}</span></td>
                <td><span>{vars?.iljin_today_day_h_kr}{vars?.iljin_today_day_e_kr}</span></td>
              </tr>
            </table>
          </div>
        </div>

        {
          data?.length > 0 &&
          data?.map((item, index) => {
            return (
              <div key={index} className="pbox1">
                <div className="heading">
                  <a href="#" name={item?.code}>
                    <h4 className=""><span dangerouslySetInnerHTML={{__html: item?.title}}></span></h4>
                  </a>
                </div>
                {
                  item.title === '월별운'
                  ? <ul className="month_unse">
                      <React.Fragment dangerouslySetInnerHTML={{__html: item.text}}></React.Fragment>
                    </ul>
                  : <div className="pbox1-txt" dangerouslySetInnerHTML={{__html: item.text}}></div>
                }
              </div>
            )
          })
        }

        {/*
        <section className="more-service pbox1">
          <div className="heading">
            <h4>택일 서비스</h4>
          </div>


          <div className="f16 mt30">{summary[0]?.summary}</div>

          <div className="mt10">
            <Button
              //color="success"
              variant="contained"
              className="hbasic-btn green mw100p"
              onClick={() => {
                navigate('/service?serviceSeq=15');
              }}
            >결혼택일 보기</Button>
          </div>

          <div className="f16 mt30">{summary[1]?.summary}</div>

          <div className="mt10">
            <Button
              //color="success"
              variant="contained"
              className="hbasic-btn green mw100p"
              onClick={() => {
                navigate('/service?serviceSeq=16');
              }}
            >이사택일 보기</Button>
          </div>
        </section>
        */}

        <div className="center mt40 pb40">
          <Button
            variant="contained"
            className="hbasic-btn greenL fr"
            onClick={() => navigate('/')}
          >첫 화면으로</Button>

        </div>
      </div>

    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default ServiceTodayResult;