import { createSlice } from '@reduxjs/toolkit';

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: {
    user: {},
    isLogout: false
  },
  reducers: {
    setUserInfo: ( state, action ) => {
      return {
        user: action.payload,
        isLogout: false
      };
    },
    logout: (state) => {
      return {
        user: {},
        isLogout: true
      }
    },
    setIsLogout: (state) => {
      return {
        user: {},
        isLogout: false
      }
    }
  }
})

export const { setUserInfo, logout, setIsLogout } = userInfoSlice.actions;
export default userInfoSlice.reducer;