/*****************************************************************************************
 * 설명 : to top 버틑
 * URI :
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const TopButton = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scroll({
        top: 0,
        behavior: 'smooth'
    })
  }

  useEffect(() => {
    const handleShowButton = () => {
      if (window.scrollY > 500) {
          setShowButton(true)
      } else {
          setShowButton(false)
      }
    }

    window.addEventListener("scroll", handleShowButton)
    return () => {
        window.removeEventListener("scroll", handleShowButton)
    }
  }, [])

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return showButton && (
    <div className="pc-only scroll__container">
      <Button id="top" className="to-top" onClick={scrollToTop}><KeyboardArrowUpIcon/></Button>
    </div>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default TopButton;