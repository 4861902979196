/*****************************************************************************************
 * 설명 : 이메일 찾기
 * URI : /findEmail/result
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import Button from '@mui/material/Button';
import moment from 'moment';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from "react-router-dom";

import { Restful } from 'service/restful';

import { showDialog } from 'reducers/dialogReducer';

import Layout from 'pages/homepage/layout/layout';

import 'pages/homepage/member/member.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const FindEmailResult = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const dispatch = useDispatch()
  const [getApi] = Restful();
  const navigate = useNavigate();

  const [info, setInfo] = useState(false);
  const [query, setQuery] = useState({});

  /***************************************************************************************
   * 설명 : 아이디 정보 가져오기
  ***************************************************************************************/
  const getMemberId = () => {
    let params = {
      program: 'home',
      service: 'member',
      version: '1.0',
      action: 'findEmailResult',
      eid: query?.eid
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result ) {
        setInfo(response.data.data);

      } else {
        dispatch(showDialog({header: '에러', message: response?.data?.message || '서버와의 통신에 실패하였습니다.'}));
      }
    });
  }

  /***************************************************************************************
   * 설명 : 아이디 정보 가져오기
  ***************************************************************************************/
  useEffect(() => {
    if( (query?.eid ?? '') !== '' ) {
      getMemberId();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query?.eid]);

  /***************************************************************************************
   * 설명 : 쿼리 스트링 가져오기
  ***************************************************************************************/
  useEffect(() => {
    let tmp = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    setQuery(tmp);

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <section className ="member-home">
        <h1 className="page-title login-title">이메일 찾기</h1>

        <div className="login-wrap">

          { info &&
            <section>
              <div className="result-txt">입력하신 정보로 등록된 고객 계정은 다음과 같습니다.<br />힌트를 참고해 로그인을 진행하세요.</div>

              <table className="table-basic table-result">
                <colgroup>
                  <col width="60%" />
                  <col width="40%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>이메일</th>
                    <th>등록일</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{info?.email}</td>
                    <td>{moment(info?.created_at).format('YYYY-MM-DD')}</td>
                  </tr>
                </tbody>
              </table>

              <Button
                variant="contained"
                className="login-btn green mt30"
                onClick={() => {
                  navigate("/login/email");
                }}
              >이메일 로그인</Button>
            </section>
          }

          { !info &&
            <secion>
              <div className="result-div">입력하신 정보로 등록된 이메일 계정이<br />존재하지 않습니다.</div>

              <NavLink to="/find/email" className="info-txt3">다시 입력할게요 &gt;&gt;</NavLink>
              <NavLink to="/login" className="info-txt3">다른 방법으로 로그인할게요 &gt;&gt;</NavLink>
            </secion>
          }

        </div>
      </section>

    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default FindEmailResult;