/*****************************************************************************************
 * 설명 : 관리자 로그인
*****************************************************************************************/
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import { useEffect } from "react";
import { Cookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";

import { CheckBoxEx, InputEx } from 'components/inputEx';
import alertMsg from 'components/message';

import { setUserInfo } from 'reducers/userReducer';

import { Restful } from 'service/restful';

import { MESSAGE_DELAY } from "config/config";
import HeaderLogin from "pages/admin/layout/headerLogin";
import './login.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
function AdminLogin(props) {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const user = useSelector(state => state?.userInfo?.user);
  const listMenu = useSelector(state => state?.menuList);

  const [getApi] = Restful();

  const formik = useFormik({
    initialValues: {
      adminId: '',
      adminPw: '',
      saveId: false
    },
    validationSchema: Yup.object().shape({
      adminId: Yup.string().min(4, '최소4자').max(20, "20자리").required('필수'),
      adminPw: Yup.string().min(4, '최소4자').max(20, "20자리").required('필수')
    }),
    onSubmit: (values) => {

      let params = {
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'login',
        adminId: values.adminId,
        adminPw: values.adminPw,
        saveId: false
      }

      getApi("post", params).then( response => {
        if( response !== undefined && response.data.result ) {
          // 아이디 저장
          if (values.saveId)
            cookies.set('SAJUIN_ID', values.adminId);
          else
            cookies.remove('SAJUIN_ID');

          alertMsg(response.data.message, "S", MESSAGE_DELAY);

          if( response.data.data && response.data.data.length > 0 )
            dispatch(setUserInfo(response.data.data[0]));
          else
            dispatch(setUserInfo({}));

          // 첫페이지로 이동
          if( response.data.dashboard ) {
            let tmp = response.data.dashboard;
            let url = tmp.linkUrl + ( tmp.queryString !== '' ? '?' + tmp.queryString : '');
            props.history(url);

          } else {
            props.history('/admin/dashboard');
          }

        } else {
          alertMsg(response.data.message, "E", MESSAGE_DELAY);
        }
      });
    }
  });

  /***************************************************************************************
   * 설명 : 아이디 저장 체크
  ***************************************************************************************/
  useEffect(() => {
    if( user.id !== undefined && user.id !== '' ) {
      let params = {
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getDashboard'
      }

      getApi("get", params).then( response => {
        if( response !== undefined && response.data.result ) {
          let tmp = response.data.dashboard;
          let url = tmp.linkUrl + ( tmp.queryString !== '' ? '?' + tmp.queryString : '');
          props.history(url);

        } else {
          props.history('/admin/dashboard');
        }
      });
    }

    // eslint-disable-next-line
  }, [user]);

  /***************************************************************************************
   * 설명 : 아이디 저장 체크
  ***************************************************************************************/
  useEffect(() => {
    const id = cookies.get('SAJUIN_ID');

    if( id !== undefined && id !== 'undefined' ) {
      formik.setFieldValue('adminId', id);
      formik.setFieldValue('saveId', true);
    }

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <>
      <HeaderLogin></HeaderLogin>

      <form className="Login" onSubmit={formik.handleSubmit}>
        <section className="login-wrap">
          <div className="login-logo"></div>

          <div className="inputbox">
            <InputEx
              name="adminId"
              formik={formik}
              label="아이디를 입력하세요"
              InputProps={{focus: "true"}}
              InputLabelProps={{shrink: true}}
            />
          </div>

          <div className="inputbox mt10">
            <InputEx
              type="password"
              name="adminPw"
              formik={formik}
              label="비밀번호를 입력하세요"
              InputLabelProps={{shrink: true}}
            />
          </div>

          <Button
            type="submit"
            color="primary"
            variant="contained"
            className="login-btn white"
          >로그인</Button>

          <CheckBoxEx
            name="saveId"
            //color="primary"
            formik={formik}
            className="darkblue"
            label="아이디 저장"
          />

          <p className="footer-txt">Copyright ⓒ 2016. SAJUIN. All Rights Reserved.</p>
        </section>
      </form>
    </>
  )
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default AdminLogin;