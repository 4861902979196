/*****************************************************************************************
 * 설명 : 페이지 상단
 * URI :
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Path = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const currentMenu = useSelector(state => state?.menuList.current);
  const [parent, setParent] = useState([]);

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  useEffect(() => {
    if( currentMenu ) {
      if( currentMenu.parent?.length > 0 )
        setParent(currentMenu.parent[0]);
      else
        setParent(currentMenu.parent);
    }

  }, [currentMenu]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="home-subHeader">
      <div className="bg"></div>
      <div className="inner">
        <h3>사주인 서비스</h3>
        <p className="txt">인생은 가보지 않은 길을 걷는 것과 같습니다.<br />네 기둥(四柱) 여덟 글자(八字) 속에 담긴 지도를 찾아보세요.</p>
        <div>
          <ul className="path">
            <li><NavLink to="/">Home</NavLink></li>
            {
              (parent?.pageName ?? '') !== '' &&
              <li>{parent?.pageName}</li>
            }

            <li>{currentMenu?.pageMenuName}</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Path;