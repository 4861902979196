/*****************************************************************************************
 * 설명 : 페이지 서브 레이아웃
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { useEffect } from 'react';
import { handleScrollTop } from 'service/utils';

import Footer from "pages/homepage/layout/footer";
import Header from "pages/homepage/layout/header";

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Layout = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  useEffect(() => {
    handleScrollTop('#top');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search, Location])

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <div id="#top" className="contents-body">
      <Header></Header>

      <section className="home-contents">
        {props.children}
      </section>

      <Footer></Footer>
    </div>
  )
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Layout;