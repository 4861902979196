/*****************************************************************************************
 * 설명 : 페이지 라우팅
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import { IndicatorContext } from 'config/indicator';

import 'assets/css/print.scss';

import AdminLogin from "pages/admin/login/login";

import Qna from "pages/homepage/board/qna";
import Gunghap from "pages/homepage/gunghap/gunghap";
import FindEmailResult from "pages/homepage/member/findEmail/findEmailResult";
import Join from "pages/homepage/member/join/join";
import Profile from "pages/homepage/member/join/profile";
import Login from "pages/homepage/member/login/login";
import LoginEmail from "pages/homepage/member/login/loginEmail";
import Recommend from "pages/homepage/recommend/recommend";
import Saju from "pages/homepage/saju/saju";
import Giftcard from "pages/homepage/services/giftcard";
import Payment from "pages/homepage/services/payment";
import Result from "pages/homepage/services/serviceTodayResult";
import ServiceResultShow from './homepage/services/result/show';

// 홈페이지

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const SiteRouter = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const { setIndicator } = useContext(IndicatorContext);

  const menuList = useSelector(state => state?.menuList);
  const [moduleList, setModuleList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);  // 로딩 상태 추가

  /***************************************************************************************
   * 설명 : 모듈 컴포넌트 로딩
  ***************************************************************************************/
  useEffect(() => {
    if( menuList.list && menuList.list.length > 0 ) {
      let module = [];

      menuList.list.forEach((menu) => {
        if( menu.modulePath && menu.modulePath !== null ) {
          const SomeComponent = React.lazy(() => import(`${menu.modulePath}`));

          module.push({
            linkUrl: menu.linkUrl,
            isRouter: menu.isRouter,
            moduleName: menu.moduleName,
            modulePath: menu.modulePath,
            module: SomeComponent
          });
        }
      });

      setModuleList(module);
      setIsLoading(false);
    }

    // eslint-disable-next-line
  }, [menuList]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Suspense fallback={() => setIndicator(true)}>
      <Routes>
        <Route exact="true" path="/admin" element={<AdminLogin indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/admin/login" element={<AdminLogin indicator={props.indicator} history={props.history} />}></Route>

        <Route exact="true" path="/" element={<Recommend indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/recommend" element={<Recommend indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/saju" element={<Saju indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/gunghap" element={<Gunghap indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/login" element={<Login indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/login/email" element={<LoginEmail indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/findEmail/result" element={<FindEmailResult indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/join" element={<Join indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/member/profile" element={<Profile indicator={props.indicator} history={props.history} />}></Route>

        <Route exact="true" path="/payment" element={<Payment indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/giftcard" element={<Giftcard indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/result" element={<Result indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/qna" element={<Qna indicator={props.indicator} history={props.history} />}></Route>
        <Route exact="true" path="/service/show" element={<ServiceResultShow indicator={props.indicator} history={props.history}/>}></Route>
        <Route exact="true" path="/services/show" element={<ServiceResultShow indicator={props.indicator} history={props.history}/>}></Route>
        {/*
        <Route exact="true" path="/mypage/storageBox" element={<StorageBox />}></Route>
        <Route exact="true" path="/mypage/edit" element={<MemberEdit />}></Route>

        <Route exact="true" path="/result" element={<Result />}></Route>
        <Route exact="true" path="/service/today" element={<ServiceToday />}></Route>
        <Route exact="true" path="/service/today/detail" element={<ServiceTodayDetail />}></Route>
        <Route exact="true" path="/service/today/result" element={<ServiceResult />}></Route>
        */}

        { // 관리자 페이지
          window.location.pathname.indexOf('/admin') > -1 &&
          <>
            {
              moduleList && moduleList.length > 0 &&
              moduleList.map((item, index) => {
                if( item.isRouter !== 0 && item.isRouter !== '0' ) {
                  var Module = item.module;

                  return (
                    <Route key={'admin_' + item.seq + '_' + index} exact="true" path={item.linkUrl} element={<Module indicator={props.indicator} history={props.history} />}></Route>
                  )
                } else {
                  return '';
                }
              })

            }
          </>
        }

        { // 사용자 페이지
          window.location.pathname.indexOf('/admin') < 0 &&
          <>
            {
              moduleList && moduleList.length > 0 &&
              moduleList.map((item, index) => {
                if( item.isRouter !== 0 && item.isRouter !== '0' ) {
                  var Module = item.module;
                  return (
                    <Route key={'home_' + item.seq + '_' + index} exact="true" path={item.linkUrl} element={<Module />}></Route>
                  )
                } else {
                  return '';
                }
              })
            }
          </>
        }

        { ! isLoading &&
          <Route path="*" element={<Navigate to="/" />} />
        }
      </Routes>
    </Suspense>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default SiteRouter;

