/*****************************************************************************************
 * 설명 : 로그인 header
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import React from 'react';
import { NavLink } from 'react-router-dom';

import './header.scss'
import logo from 'assets/images/logo.png';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const HeaderLogin = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="admin-header">
      <NavLink to="/admin" className="logo">
        <img src={logo} alt="사주인"/>
      </NavLink>

      <ul className="util-area">
        <li className="top-icon">
          <a href="https://remotedesktop.google.com/support" target="_blank" rel="noreferrer" title="원격지원">
            <span className="ico-firstremote right-icon"></span>
          </a>
        </li>
        <li className="top-icon">
          <a href="/" target="_blank" rel="noreferrer" title="홈페이지">
            <span className="material-icons right-icon">home</span>
          </a>
        </li>
      </ul>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default HeaderLogin;