import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
//import logger from 'redux-logger'
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import dialogReducer from 'reducers/dialogReducer';
import indicatorReducer from 'reducers/indicatorReducer';
import menuListReducer from 'reducers/menuListReducer';
import siteInfoReducer from 'reducers/siteInfoReducer';
import userInfoReducer from 'reducers/userReducer';

const store = configureStore({
  reducer: {
    userInfo: userInfoReducer,
    menuList: menuListReducer,
    siteInfo: siteInfoReducer,
    indicator: indicatorReducer,
    dialog: dialogReducer
  },
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== 'production'
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
