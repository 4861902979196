import { createSlice } from '@reduxjs/toolkit';

const indicatorSlice = createSlice({
  name: 'indicator',
  initialState: {
    history: []
  },
  reducers: {
    setIndicatorListAdd: ( state, action ) => {
      return {
        history: [...state.history, action.payload],
      }
    },
    setIndicatorListDel: ( state ) => {
      let tmp = [...state.history];
      tmp.pop();

      return {
        history: tmp,
      }
    }
  }
})

export const { setIndicatorListAdd, setIndicatorListDel } = indicatorSlice.actions;
export default indicatorSlice.reducer;