/*****************************************************************************************
 * 설명 : 인디케이터 표시 처리
 * 작성자 : 송영석
 * 작성일 : 2021-05-21
*****************************************************************************************/
import CircularProgress from '@mui/material/CircularProgress';

import styled from 'styled-components';

/***************************************************************************************
 * 설명 : Indicator 를 감싸는 wrap <section> tag
 * 입력값 : isShow = true / false 표시 여부
***************************************************************************************/
const IndicatorWrap = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: ${props => (props.isShow) ? 'block' : 'none'};
  z-index: 999998;
`;

/***************************************************************************************
* 설명 : Indicator 투명한 배경 영역
***************************************************************************************/
const IndicatorBackdrop = styled.div`
  position:fixed;
  width:100vw;
  height:100vh;
  background-color: rgba(0,0,0,1);
  opacity: 0.3;
  z-index: 999999999;
`;

/***************************************************************************************
* 설명 : Indicator 프로그래스 바 표시 영역
***************************************************************************************/
const IndicatorProgress = styled.div`
  position:fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align:center;
  z-index:1000000;
  height:100vh;
  left: calc(50% - 75px);
`;

const Indicator = ( props ) => {
  return (
    <IndicatorWrap isShow={props.checked}>
      <IndicatorBackdrop></IndicatorBackdrop>
      <IndicatorProgress>
        <CircularProgress size={150} />
      </IndicatorProgress>
    </IndicatorWrap>
  )
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Indicator;