/*****************************************************************************************
 * 설명 : 게시판
 * URI : /qna
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { useEffect, useState } from 'react';


import { Restful } from 'service/restful';


import LayoutSub from 'pages/homepage/layout/layoutSub';

import './board.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Qna = (props) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();

  // 공지사항 리스트
  const [list, setList] = useState([]);

  const [faqOpen, setFaqOpen] = useState([]);

  /*******************************************************************************
    설  명 : 게시판 리스트 가져오기
  *******************************************************************************/
  const getBoardList = () => {
    let params = {
      program: 'home',
      service: 'board',
      version: '1.0',
      action: 'getBoardList',
      boardId: 'faq',
      searchText: '',
      searchType: '',
      pageNo: 1,
      pageRow: 100
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data?.length > 0 ) {
        setList(response.data.data);

        let tmp = [];
        response.data.data.forEach((item) => {
          tmp.push(false);
        })

        setFaqOpen(tmp);

      } else {
        setList([]);
      }
    });
  }

  /*******************************************************************************
    설  명 : 페이징 변경 시 처리
  *******************************************************************************/
  useEffect(() => {
    getBoardList();

    // eslint-disable-next-line
  }, []);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <LayoutSub>
      <div className="board-home qna">
        <div className="board-title page-title1"><span>자주 묻는 질문</span></div>
        <div className="information-txt leftLine">
          <p><i className="fa fa-quote-left f20 pr5 pc-only"></i>자주하시는 질문과 답변입니다.</p>
        </div>

        <section className="qna-list">
          {
            list.length > 0 &&
            list.map((item, index) => {
              return (
                <div
                  key={item?.seq}
                  className={ faqOpen[index] === true ? "cp qna-box open" : "cp qna-box"}
                  onClick={() => {
                    let tmp = faqOpen.map((item) => item);
                    tmp[index] = faqOpen[index] === true ? false : true;
                    setFaqOpen(tmp);
                  }}
                >
                  <div className="qna-header">
                    <p className="qna-title" dangerouslySetInnerHTML={{__html: item.subject}}></p>
                  </div>
                  <div className="qna-body">
                    <div dangerouslySetInnerHTML={{__html: item.contents}}></div>
                  </div>
                </div>
              )
            })
          }
        </section>

        <section>
          <div className="board-title page-title1m"><span>Contact & 문의</span></div>
          <div className="information-txt">
            <p>이메일로 문의주세요^^ : sajuin@sajuin.com</p>
          </div>
        </section>

      </div>
    </LayoutSub>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Qna;