/*****************************************************************************************
 * 설명 : 마이페이지 > 사주인 서비스 안전
 * URI :
*****************************************************************************************/
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
export const SafeModal = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className="modal-wrap safe-page" style={{ width: '100%' }}>
      <header className="modal-header" style={{ cursor: 'move' }} id="draggable-dialog-title">
        <div className="modal-title"></div>
        <Button className="close-btn" onClick={props.close}>
          <CloseIcon style={{ color: '#fff', fontSize: '28px' }}></CloseIcon>
        </Button>
      </header>

      <section className="modal-body">
        <div className="txt">사주인의 서비스는 안전합니다.<br />개인정보를 안전하게 관리하기 위해 미국 아마존社의 호스팅서비스를 이용하고 있으며, 다음카카오, 네이버, 엔씨소프트 등 16,000여 회사가 선택한 다날의 결제 서비스를 사용하고 있습니다. 고객님께서 신용카드로 결제하셔도 저희 회사에는 고객님의 신용카드 정보가 남지 않으니 안심하고 이용하셔도 됩니다.</div>
      </section>

      <footer className="modal-footer">
        <div className="modal-footer-center">
          <Button
            variant="contained"
            className="Btn green"
            onClick={() => {
              props.close();
            }}
          >확인</Button>
        </div>
      </footer>
    </section>
  )
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default SafeModal;