/*****************************************************************************************
 * 설명 : 어플리케이션 설정
 *****************************************************************************************/
export const version = '1.0.0'

export const baseImgUrl = (process.env.NODE_ENV !== 'production') ? window.location.protocol + '//' + ((window.location.hostname === 'localhost') ? 'sajuin.o2oz.net' : window.location.hostname + ':8100' ) : window.location.origin;

// 기본 접속 주소
export const baseHost = (process.env.NODE_ENV !== 'production') ? 'http://' + window.location.hostname + ':8100' : window.location.origin;

export const baseURL =(process.env.NODE_ENV !== 'production') ? baseHost : baseHost;

// hrms
export const baseHRMS = (process.env.NODE_ENV !== 'production') ? 'http://new.sajuin.com' : 'https://www.sajuin.com';

// 행정정보 공동이용 서비스 주소
export const discountURL = baseURL;

export const PAGEROW = 100;

export const MESSAGE_DELAY = 2000;

export const timeList = [
  {label: '생시 모름', value: '00'},
  {label: '子/夜 (23:30 ~ 00:29)', value: '23'},
  {label: '子/朝 (00:30 ~ 01:29)', value: '01'},
  {label: '丑 (01:30 ~ 03:29)', value: '02'},
  {label: '寅 (03:30 ~ 05:29)', value: '04'},
  {label: '卯 (05:30 ~ 07:29)', value: '06'},
  {label: '辰 (07:30 ~ 09:29)', value: '08'},
  {label: '巳 (09:30 ~ 11:29)', value: '10'},
  {label: '午 (11:30 ~ 13:29)', value: '12'},
  {label: '未 (13:30 ~ 15:29)', value: '14'},
  {label: '申 (15:30 ~ 17:29)', value: '16'},
  {label: '酉 (17:30 ~ 19:29)', value: '18'},
  {label: '戌 (19:30 ~ 21:29)', value: '20'},
  {label: '亥 (21:30 ~ 23:29)', value: '22'},
];

export const timeList2 = [
  {label: '생시 모름', value: '00'},
  {label: '자子/야夜시 (23:30 ~ 00:29)', value: '23'},
  {label: '자子/조朝시 (00:30 ~ 01:29)', value: '01'},
  {label: '축丑시 (01:30 ~ 03:29)', value: '02'},
  {label: '인寅시 (03:30 ~ 05:29)', value: '04'},
  {label: '묘卯시 (05:30 ~ 07:29)', value: '06'},
  {label: '진辰시 (07:30 ~ 09:29)', value: '08'},
  {label: '사巳시 (09:30 ~ 11:29)', value: '10'},
  {label: '오午시 (11:30 ~ 13:29)', value: '12'},
  {label: '미未시 (13:30 ~ 15:29)', value: '14'},
  {label: '신申시 (15:30 ~ 17:29)', value: '16'},
  {label: '유酉시 (17:30 ~ 19:29)', value: '18'},
  {label: '술戌시 (19:30 ~ 21:29)', value: '20'},
  {label: '해亥시 (21:30 ~ 23:29)', value: '22'},
];